<template>
    <div ref="autocomplete" v-click-outside="clickOutside" class="ui-autocomplete">
        <ui-input
            ref="inputSearch"
            v-model="valueComputed"
            class="ui-autocomplete__input"
            type="text"
            autocomplete="off"
            v-bind="{ ...$attrs, ...$props }"
            :field="field"
            :disabled="loading"
            @input="typing = true"
            @click="focusInput"
        />
        <div v-if="options.length" class="ui-autocomplete__right-content">
            <button v-if="value" class="ui-autocomplete__clear-button" @click="clear">
                <svg width="18" height="18" viewBox="0 0 20 20">
                    <path
                        d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                        stroke="currentColor"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
            <span class="ui-autocomplete__arrow fas fa-chevron-down" @click="focusInput"></span>
        </div>
        <div v-show="showList && optionsFiltered.length" class="ui-autocomplete__list-container">
            <ul ref="options" class="ui-autocomplete__list bg-white shadow rounded">
                <li v-for="option in optionsFiltered" :key="`${makeID(10)}-${option}`" class="ui-autocomplete__list-item">
                    <button
                        type="button"
                        class="ui-autocomplete__list-button"
                        :class="{ 'ui-autocomplete__list-button--selected': getOptionValue(option) === valueComputed }"
                        @click="selectOption(option)"
                    >
                        {{ getOptionValue(option) }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import UiInput from '@/components/ui/inputs/Input.vue';

export default {
    name: 'UiAutocomplete',
    components: { UiInput },
    inheritAttrs: false,
    props: {
        field: {
            type: Object,
            default: null,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        value: {
            type: String,
            default: '',
            required: false,
        },
        options: {
            type: Array,
            default: () => [],
            required: false,
        },
        optionText: {
            type: String,
            default: 'label',
            required: false,
        },
    },
    data() {
        return {
            typing: false,
            showList: false,
        };
    },
    computed: {
        valueComputed: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value;
            },
        },
        optionsFiltered() {
            if (!this.valueComputed) return this.options;

            if (!this.typing) return this.options;

            return this.options.filter(option => {
                const option_value = this.getOptionValue(option);
                const regex = new RegExp(this.valueComputed, 'ig');
                const result = option_value.match(regex);

                return result;
            });
        },
    },
    mounted() {
        if (this.value) {
            this.$emit('input', this.value);
        }
    },
    methods: {
        clear() {
            this.$emit('input', '');
            this.$refs.inputSearch.focus();
        },
        clickOutside() {
            this.typing = false;
            this.showList = false;
        },
        focusInput() {
            this.showList = !this.showList;
        },
        getOptionValue(option) {
            let option_value = option;

            if (typeof option === 'object') {
                option_value = this._.get(option, this.optionText);
            }

            return option_value;
        },
        selectOption(option) {
            const option_value = this.getOptionValue(option);

            this.$emit('input', option_value);
            this.showList = false;
        },
    },
};
</script>

<style lang="scss">
.ui-autocomplete {
    position: relative;

    .ui-new-input__input {
        padding-right: 46px;
    }

    &__right-content {
        align-items: center;
        display: flex;
        height: 40px;
        position: absolute;
        right: 8px;
        top: 26px;
    }

    &__clear-button {
        align-items: center;
        border: none;
        border-radius: 100%;
        background-color: transparent;
        color: $accounts-secondary-400;
        display: flex;
        flex-shrink: 0;
        height: 22px;
        justify-content: center;
        margin-right: 2px;
        padding: 2px;
        transition: 200ms all;
        width: 22px;

        &:hover {
            background-color: $accounts-secondary-100;
        }
    }

    &__arrow {
        color: $accounts-secondary;
        content: '\f078';
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-size: 16px;
        font-weight: 900;
        line-height: 1;
    }

    &__list-container {
        position: absolute;
        z-index: 99;
        width: 100%;
        left: 0;
    }

    &__list {
        overflow-y: auto;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        max-height: 250px;
    }

    &__list-button {
        display: block;
        padding: 0.5rem 1rem;
        color: black;
        background: none;
        width: 100%;
        text-align: left;
        border: none;

        &:hover,
        &--selected {
            background-color: #f2f7eb;
        }
    }
}
</style>
