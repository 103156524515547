<template>
    <ui-card class="companies-card" :title="$t('Organizations')" :description="$t('Manage organizations related to your account.')">
        <div>
            <div v-if="loading" class="p-5 text-center">
                <span class="fas fa-spinner fa-spin"></span>
            </div>
            <template v-else>
                <template v-if="companies.length && !addingCompany">
                    <company-list :companies="companies" @companyDeleted="companyDeleted" @edit="showEditForm" />
                    <div class="text-right">
                        <button class="companies-card__add-button" @click="addingCompany = true">
                            <span class="fas fa-plus-circle"></span>
                            {{ $t('Add company') }}
                        </button>
                    </div>
                </template>
                <template v-if="addingCompany">
                    <button v-if="companies.length" variant="light" class="companies-card__back-button mb-3" @click="hideCompanyForm">
                        <em class="fas fa-chevron-left mr-1"></em>
                        {{ $t('Cancel') }}
                    </button>
                    <company-form ref="companyForm" :company="companyToEdit" />
                    <div class="text-right">
                        <ui-button class="mb-0" variant="accent" :loading="savingCompany" @click="saveCompany">
                            {{ $t(companyToEdit ? 'Update' : 'Add') }}
                        </ui-button>
                    </div>
                </template>
            </template>
        </div>
    </ui-card>
</template>

<script>
import CompanyForm from '@/components/companies/Form.vue';
import CompanyList from '@/components/companies/List.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiCard from '@/components/ui/Card.vue';

export default {
    name: 'ProfileCard',
    components: {
        CompanyForm,
        CompanyList,
        UiButton,
        UiCard,
    },
    data() {
        return {
            addingCompany: false,
            companies: [],
            companyToEdit: null,
            loading: false,
            savingCompany: false,
        };
    },
    mounted() {
        this.getCompanies();
    },
    methods: {
        hideCompanyForm() {
            this.addingCompany = false;
            this.companyToEdit = null;
        },
        companyDeleted(id) {
            const search_index = this.companies.findIndex(item => item.id === id);

            if (search_index >= 0) {
                this.$delete(this.companies, search_index);
            }
        },
        showEditForm(company) {
            this.companyToEdit = { ...company, addresses: [...company.addresses] };
            this.addingCompany = true;
        },
        async getCompanies() {
            try {
                this.loading = true;

                const { data } = await this.axiosAccount.get('/api/companies');

                this.companies = data.docs;

                if (!this.companies.length) {
                    this.addingCompany = true;
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async saveCompany() {
            try {
                this.savingCompany = true;

                if (!this.$refs.companyForm.validate()) {
                    return;
                }

                const { data } = await this.axiosAccount({
                    method: this.companyToEdit ? 'PUT' : 'POST',
                    url: this.companyToEdit ? `/api/companies/${this.companyToEdit.id}` : '/api/companies',
                    data: { ...this.$refs.companyForm.getFormData() }
                });

                if (this.companyToEdit) {
                    const search_index = this.companies.findIndex(item => item.id === data.id);

                    if (search_index >= 0) {
                        this.companies.splice(search_index, 1, { ...data });
                    }
                } else {
                    this.companies.unshift(data);
                }

                this.$toasted.global.successMessage(this.$t(
                    this.companyToEdit ?  'Company successfully updated' : 'Successfully created company'
                ));
                this.hideCompanyForm();
            } catch (error) {
                this.showError(error);
            } finally {
                this.savingCompany = false;
            }
        },
    },
};
</script>

<style lang="scss">
.companies-card {
    &__legend {
        color: $general-black;
        font-size: 1rem;
        margin-bottom: 10px;
    }

    &__add-button {
        background-color: transparent;
        border: 0;
        color: $accounts-primary-600;
        font-weight: 700;
        margin-top: 10px;
        padding: 8px 0;

        span {
            margin-right: 5px;
        }
    }

    &__back-button {
        background-color: #eeeeee;
        border: none;
        border-radius: 8px;
        padding: 4px 8px;
        transition: 0.15s ease-in-out;

        &:hover {
            background-color: #dbdbdb;
        }
    }
}
</style>
