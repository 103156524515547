<template>
    <div class="company-form">
        <p class="company-form_section-text">
            <span class="fas fa-building"></span>
            {{ $t('General information') }}
        </p>
        <div class="row">
            <div class="col-md-12 mb-2">
                <ui-input
                    id="name"
                    v-model="formData.name"
                    name="name" type="text"
                    placeholder="Example S.A. de C.V."
                    :label="$t('Name')"
                    :field="v$.formData.name"
                />
            </div>
            <div class="col-md-12 mb-2">
                <ui-input
                    id="email"
                    v-model="formData.email"
                    name="email" type="text"
                    placeholder="email@example.com"
                    :label="$t('Email')"
                    :field="v$.formData.email"
                />
            </div>
            <div class="col-md-12 mb-2">
                <ui-phone-input
                    id="phone"
                    v-model="formData.phone"
                    name="phone"
                    placeholder="55 1254 5678"
                    :label="$t('Phone')"
                    :field="v$.formData.phone"
                    :auto-default-country="!company"
                    :default-country="defaultPhoneCountry"
                />
            </div>
        </div>
        <p class="company-form_section-text">
            <span class="fas fa-address-book"></span>
            {{ $t(company ? 'Addresses' : 'Address') }}
        </p>
        <template v-if="company">
            <address-list
                class="company-form__address-list"
                :class="{ 'company-form__address-list--invalid': v$.formData.addresses.$dirty && v$.formData.addresses.$error }"
                :addresses="formData.addresses"
                @setAsDefault="setAddressAsDefault"
                @remove="removeAddress"
            />
            <ui-invalid-feedback :field="v$.formData.addresses" />
            <div class="mb-2">
                <button class="companies-card__add-button" @click="$refs.addressModal.show()">
                    <span class="fas fa-plus-circle"></span>
                    {{ $t('Add address') }}
                </button>
            </div>
        </template>
        <address-form v-else ref="addressForm" />
        <ui-modal
            ref="addressModal"
            :title="$t('Add address')"
            @confirm="addAddress"
        >
            <address-form ref="addressFormModal" />
            <template #footer>
                <ui-button variant="secondary" :disabled="addingAddress" @click="$refs.addressModal.close()">
                    {{ $t('Cancel') }}
                </ui-button>
                <ui-button variant="primary" :loading="addingAddress" @click="addAddress">
                    {{ $t('Save') }}
                </ui-button>
            </template>
        </ui-modal>
    </div>
</template>

<script>
import { parsePhoneNumber } from 'libphonenumber-js'
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';

import AddressForm from '@/components/companies/AddressForm.vue';
import AddressList from '@/components/companies/AddressList.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiInvalidFeedback from '@/components/ui/InvalidFeedback.vue';
import UiModal from '@/components/ui/modal/Modal.vue';
import UiPhoneInput from '@/components/ui/inputs/Phone.vue';

export default {
    name: 'CompanyForm',
    components: {
        AddressForm,
        AddressList,
        UiButton,
        UiInput,
        UiInvalidFeedback,
        UiModal,
        UiPhoneInput,
    },
    props: {
        company: {
            type: Object,
            default: null,
            required: false,
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            addingAddress: false,
            formData: {
                name: '',
                email: '',
                phone: '',
                addresses: [], 
            },
        };
    },
    computed: {
        rules() {
            const rules = {
                formData: {
                    name: {
                        required: helpers.withMessage(this.$t('validations.required', { field: this.$t('name') }), required),
                    },
                    email: {
                        required: helpers.withMessage(this.$t('validations.required', { field: this.$t('email') }), required),
                        email: helpers.withMessage(this.$t('Email invalid'), email),
                    },
                    phone: {
                        required: helpers.withMessage(this.$t('validations.required', { field: this.$t('phone') }), required),
                    },
                },
            };

            if (this.company) {
                rules.formData.addresses = { required, minLength: minLength(1) };
            }

            return rules;
        },
        defaultPhoneCountry() {
            const phone = this.company?.phone;

            if (phone) {
                try {
                    const { country } = parsePhoneNumber(`+${phone}`);
    
                    if (country) {
                        return country;
                    }
                } catch {
                    return null;
                }
            }

            return null;
        },
    },
    validations() {
        return this.rules;
    },
    mounted() {
        this.setDefaultValues();
    },
    methods: {
        validate() {
            this.v$.$touch();
            
            const address_form = this.$refs.addressForm;

            if (address_form) {
                return address_form.validate() && !this.v$.$invalid;
            }

            return !this.v$.$invalid;
        },
        setDefaultValues() {
            if (!this.company) {
                return;
            }

            Object.keys(this.formData).forEach(key => {
                const value = this.company[key];

                if (value) {
                    this.formData[key] = value;
                }
            });
        },
        getFormData() {
            const form_data = { ...this.formData };
            const address_form = this.$refs.addressForm;

            if (address_form) {
                form_data.addresses = [{ ...address_form.formData }];
            }

            return form_data;
        },
        setAddressAsDefault(index) {
            if (this.formData.addresses[index].main) {
                return;
            }

            const current_default_index = this.formData.addresses.findIndex(item => item.main === true);

            if (current_default_index >= 0) {
                this.formData.addresses[current_default_index].main = false;
            }

            this.formData.addresses[index].main = true;
        },
        removeAddress(index) {
            this.$delete(this.formData.addresses, index)
        },
        addAddress() {
            try {
                this.addingAddress = true;

                if (!this.$refs.addressFormModal.validate()) {
                    return;
                }

                this.formData.addresses.unshift({ ...this.$refs.addressFormModal.formData });
                this.$refs.addressModal.close();
            } catch (error) {
                this.showError(error);
            } finally {
                this.addingAddress = false;
            }
        },
    },
};
</script>

<style lang="scss">
.company-form {
    &__section-text {
        color: $accounts-secondary-400;
    }

    &__address-list {
        &--invalid {
            .ui-list-group-item {
                border-color: $general-error;
            }
        }
    }
}
</style>
