<template>
    <div class="company-list">
        <ui-list-group>
            <company-list-item
                v-for="company in companies"
                :key="company.id"
                :company="company"
                @delete="showDeleteModal"
                @edit="$emit('edit', $event)"
            />
        </ui-list-group>
        <ui-modal
            ref="deleteCompanyModal"
            centered
            :title="$t('Remove company')"
        >
            <p class="mb-0">{{ $t('Are you sure to delete this company from your account?') }}</p>
            <template #footer>
                <ui-button variant="secondary" :disabled="deletingCompany" @click="$refs.deleteCompanyModal.close()">
                    {{ $t('Cancel') }}
                </ui-button>
                <ui-button variant="primary" :loading="deletingCompany" @click="deleteCompany">
                    {{ $t('Delete') }}
                </ui-button>
            </template>
        </ui-modal>
    </div>
</template>

<script>
import CompanyListItem from '@/components/companies/ListItem.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/modal/Modal.vue';
import UiListGroup from '@/components/ui/list-group/ListGroup.vue';

export default {
    name: 'CompanyList',
    components: { CompanyListItem, UiButton, UiModal, UiListGroup },
    props: {
        companies: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    data() {
        return {
            deletingCompany: false,
            selectedCompany: null,
        };
    },
    methods: {
        showDeleteModal(company_id) {
            this.$refs.deleteCompanyModal.show();
            this.selectedCompany = company_id;
        },
        async deleteCompany() {
            try {
                this.deletingCompany = true;

                await this.axiosAccount.delete(`/api/companies/${this.selectedCompany}`);

                this.$refs.deleteCompanyModal.close();
                this.$emit('companyDeleted', this.selectedCompany);
                this.selectedCompany = null;
                this.$toasted.global.successMessage(this.$t('Company successfully removed'));
            } catch(error) {
                this.showError();
            } finally {
                this.deletingCompany = false;
            }
        },
    }
};
</script>
