<template>
    <ui-list-group-item class="company-list-item">
        <div class="company-list-item__container">
            <div class="company-list-item__details-content">
                <img class="company-selector__icon" src="@/assets/images/icons/company-icon.svg" alt="Company" />
                <div>
                    <div class="d-flex align-items-center">
                        <span class="company-list-item__name">
                            {{ company.name }}
                        </span>
                    </div>
                    <span class="company-list-item__address">
                        {{ company.addresses[0].street }}, {{ company.addresses[0].ext_number }},
                        {{ company.addresses[0].district }}, {{ company.addresses[0].city }} {{ company.addresses[0].state }},
                        {{ company.addresses[0].country }}.
                    </span>
                </div>
            </div>
            <div class="company-list-item__actions">
                <button class="company-list-item__action company-list-item__action--edit" @click="$emit('edit', company)">
                    <span class="fas fa-edit"></span>
                </button>
                <button class="company-list-item__action company-list-item__action--delete" @click="$emit('delete', company.id)">
                    <span class="fas fa-trash"></span>
                </button>
            </div>
        </div>
    </ui-list-group-item>
</template>

<script>
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';

export default {
    name: 'CompanyListItem',
    components: { UiListGroupItem },
    props: {
        company: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
};
</script>

<style lang="scss">
.company-list-item {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    &__icon {
        width: 35px;
        height: auto;
    }

    &__details-content {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    &__name {
        color: $general-black;
        font-weight: 700;
        font-size: 1rem;
    }

    &__address {
        color: rgba(#212529, 0.6);
        font-weight: 500;
        font-size: 0.9rem;
    }

    &__actions {
        display: flex;
        gap: 4px;
    }

    &__action {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: $accounts-secondary-200;
        display: flex;
        height: 30px;
        justify-content: center;
        transition: 0.15s ease-in-out;
        width: 30px;

        &:hover {
            background-color: #e9e9e9;
        }

        &--edit {
            &:hover {
                background-color: rgba($general-info, 0.1);
                color: $general-info;
            }
        }

        &--delete {
            &:hover {
                background-color: rgba($general-error, 0.1);
                color: $general-error;
            }
        }
    }
}
</style>