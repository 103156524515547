<template>
    <div class="personal-info">
        <companies-card />
    </div>
</template>

<script>
import CompaniesCard from '@/components/profile/CompaniesCard.vue';

import iframeMixin from '@/plugins/mixin/iframe.mixin';
import supportChatUtils from '@/utils/support-chat.utils';

export default {
    name: 'personal-info',
    components: { CompaniesCard },
    mixins: [iframeMixin],
    mounted() {
        if (this.itsAnIframe) {
            supportChatUtils.hideChat();
        }
    },
};
</script>
