<template>
    <div class="address-form row">
        <div class="col-md-12 mb-2">
            <ui-select
                id="country"
                v-model="formData.country"
                name="country"
                option-label="name"
                option-value="code"
                :label="$t('Country')"
                :placeholder="$t('Choose an option')"
                :field="v$.formData.country"
                :options="countries"
                :loading="loadingCountries"
                @change="onChangeCountry"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-input
                id="postal_code"
                v-model="formData.postal_code"
                name="postal_code"
                type="text"
                placeholder="56789"
                :debounce="500"
                :label="$t('Postal code')"
                :field="v$.formData.postal_code"
                @change="onChangePostalCode"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-input
                id="street"
                v-model="formData.street"
                name="street"
                type="text"
                placeholder="Park Avenue"
                :label="$t('Street')"
                :field="v$.formData.street"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-input
                id="ext_number"
                v-model="formData.ext_number"
                name="ext_number"
                type="text"
                placeholder="1"
                :label="$t('External number')"
                :field="v$.formData.ext_number"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-input
                id="int_number"
                v-model="formData.int_number"
                name="int_number"
                type="text"
                placeholder="2"
                :label="$t('Internal number')"
                :field="v$.formData.int_number"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-autocomplete
                id="district"
                v-model="formData.district"
                name="district"
                type="text"
                placeholder="Obispado"
                :label="$t('District')"
                :field="v$.formData.district"
                :options="suburbs"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-input
                id="city"
                v-model="formData.city"
                name="city"
                type="text"
                placeholder="Monterrey"
                :label="$t('City')"
                :field="v$.formData.city"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-select
                id="state"
                v-model="formData.state"
                name="state"
                option-label="name"
                option-value="id"
                :label="$t('State')"
                :placeholder="$t('Choose an option')"
                :field="v$.formData.state"
                :options="states"
                :loading="loadingStates"
            />
        </div>
        <div class="col-md-12 mb-2">
            <ui-checkbox
                id="main"
                v-model="formData.main"
                name="main"
                :checked-value="true"
                :unchecked-value="false"
            >
                {{ $t('Set as default') }}
            </ui-checkbox>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators';

import UiAutocomplete from '@/components/ui/inputs/Autocomplete.vue';
import UiCheckbox from '@/components/ui/inputs/Checkbox.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiSelect from '@/components/ui/inputs/Select.vue';

export default {
    name: 'CompanyAddressForm',
    components: {
        UiAutocomplete,
        UiCheckbox,
        UiInput,
        UiSelect,
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            countries: [],
            formData: {
                street: '',
                ext_number: '',
                int_number: '',
                city: '',
                district: '',
                state: '',
                postal_code: '',
                country: '',
                main: false,
            },
            loadingCountries: false,
            loadingStates: false,
            suburbs: [],
            states: [],
        };
    },
    validations() {
        return {
            formData: {
                street: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('street') }), required),
                },
                ext_number: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('external number') }), required),
                },
                int_number: {},
                city: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('city') }), required),
                },
                district: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('district') }), required),
                },
                state: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('state') }), required),
                },
                postal_code: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('postal code') }), required),
                },
                country: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('country') }), required),
                },
            },
        };
    },
    mounted() {
        this.getCountries();
    },
    methods: {
        validate() {
            this.v$.$touch();
            return !this.v$.$invalid;
        },
        onChangeCountry(value) {
            this.formData.state = '';

            if (value) {
                this.getStates(value);
                return;
            }

            this.states = [];
        },
        async onChangePostalCode(postal_code) {
            if (this.formData.country && postal_code) {
                try {
                    this.loadingPostalCode = true;

                    const { data } = await this.axiosAccount.get(`/api/catalogs/zipcode/${this.formData.country}/${postal_code}`);
                    const response_data = data[0];
                    const [district] = response_data.suburbs;

                    this.formData.district = district;
                    this.formData.city = response_data.locality;
                    this.formData.state = response_data.state.id;
                    this.suburbs = response_data.suburbs;
                } catch (error) {
                    const error_code = error?.response?.status;

                    if (error_code === 404) {
                        this.suburbs = [];
                    } else {
                        this.showError(error);
                    }
                } finally {
                    this.loadingPostalCode = false;
                }
            }
        },
        async getCountries() {
            try {
                this.loadingCountries = true;

                const { data } = await this.axiosAccount.get('/api/catalogs/countries');

                this.countries = data;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loadingCountries = false;
            }
        },
        async getStates(country) {
            try {
                this.loadingStates = true;

                const { data } = await this.axiosAccount.get(`/api/catalogs/states/${country}`);

                this.states = data;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loadingStates = false;
            }
        },
    },
};
</script>
