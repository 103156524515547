<template>
    <ui-list-group class="company-address-list">
        <ui-list-group-item v-for="(address, key) in addresses" :key="key" class="company-list-item">
            <div class="company-list-item__container">
                <div class="company-list-item__details-content">
                    <div class="company-selector__icon">
                        <span class="fas fa-map-marker-alt"></span>
                    </div>
                    <p class="company-list-item__address mb-0">
                        {{ address.street }}, {{ address.ext_number }},
                        {{ address.district }}, {{ address.city }} {{ address.state }},
                        {{ address.country }}.
                    </p>
                </div>
                <div class="company-address-list__actions">
                    <button
                        class="company-address-list__action company-address-list__action--default"
                        :class="{ 'company-address-list__action--active': address.main }"
                        @click="$emit('setAsDefault', key)"
                    >
                        <span class="fas fa-star"></span>
                    </button>
                    <button class="company-address-list__action company-address-list__action--delete" @click="$emit('remove', key)">
                        <span class="fas fa-trash"></span>
                    </button>
                </div>
            </div>
        </ui-list-group-item>
        <ui-list-group-item v-if="!addresses.length" class="company-list-item">
            <p class="mb-0 py-2 text-center text-muted">
                <span class="fas fa-info-circle"></span>
                {{ $t('You do not have registered addresses for this company yet.') }}
            </p>
        </ui-list-group-item>
    </ui-list-group>
</template>

<script>
import UiListGroup from '@/components/ui/list-group/ListGroup.vue';
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';

export default {
    name: 'CompanyAddressList',
    components: { UiListGroup, UiListGroupItem },
    props: {
        addresses: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
};
</script>

<style lang="scss">
.company-address-list {
    &__actions {
        display: flex;
        gap: 4px;
    }

    &__action {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: $accounts-secondary-200;
        display: flex;
        height: 30px;
        justify-content: center;
        transition: 0.15s ease-in-out;
        width: 30px;

        &:hover {
            background-color: #e9e9e9;
        }

        &--default {
            &:hover {
                background-color: rgba($general-info, 0.1);
                color: $general-info;
            }
        }

        &--active {
            color: $general-info;
        }

        &--delete {
            &:hover {
                background-color: rgba($general-error, 0.1);
                color: $general-error;
            }
        }
    }
}
</style>
